body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

::-webkit-scrollbar{
    display: none
}
.scroll-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Inter-Bold;
        src: url(../public/fonts/Inter-Bold.ttf);
    }

    @font-face {
        font-family: Inter-Light;
        src: url(../public/fonts/Inter-Light.ttf);
    }

    @font-face {
        font-family: Inter-Medium;
        src: url(../public/fonts/Inter-Medium.ttf);
    }

    @font-face {
        font-family: Inter-Regular;
        src: url(../public/fonts/Inter-Regular.ttf);
    }

    @font-face {
        font-family: Inter-SemiBold;
        src: url(../public/fonts/Inter-SemiBold.ttf);
    }

    @font-face {
        font-family: Inter-Thin;
        src: url(../public/fonts/Inter-Thin.ttf);
    }

    @font-face {
        font-family: OpenSans-Bold;
        src: url(../public/fonts/OpenSans-Bold.ttf);
    }

    @font-face {
        font-family: OpenSans-Regular;
        src: url(../public/fonts/OpenSans-Regular.ttf);
    }

    @font-face {
        font-family: OpenSans-Medium;
        src: url(../public/fonts/OpenSans-Medium.ttf);
    }

    @font-face {
        font-family: OpenSans-SemiBold;
        src: url(../public/fonts/OpenSans-SemiBold.ttf);
    }

    @font-face {
        font-family: Roboto-Regular;
        src: url(../public//fonts/Roboto-Regular.ttf);
    }

    @font-face {
        font-family: Roboto-Medium;
        src: url(../public//fonts/Roboto-Medium.ttf);
    }

    @font-face {
        font-family: Roboto-Bold;
        src: url(../public//fonts/Roboto-Bold.ttf);
    }
}

.shimmer-container {
    background-color: gray;
    position: relative;
}
.shimmer {
    height: 100%;
    width: 100%;
    background-color: gray;
    bottom: 0;
}
.animate {
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
}
@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

.fallback-container > div {
    height: 100%;
    width: 100%;
}
.wrpr-main img {
    display: none;
}
.input-hidden input[type='radio'] {
    display: none;
}
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.tooltip_arrow {
    position: absolute;
    width: 227px;
    height: 57px;
    padding: 7px 12px;
    border-radius: 5px;
    top: -64px;
    left: 10px;
    color: #515d5f;
    font-size: 13px;
    border: 1px solid #dedede;
    background-color: #fffacf;
    border-radius: 6px;
    z-index: 999;
}

.tooltip_arrow:after {
    content: '';
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
    background-color: #fffacf;
    position: absolute;
    left: 27px;
    bottom: -7.5px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
}

.dp-toast-view {
    position: absolute;
    width: 82%;
    padding: 12px;
    margin: auto;
    color: #ffffff;
    font-size: 13px;
    background-color: #636363;
    border-radius: 4px;
    left: 0px;
    right: 0px;
    top: 24px;
    z-index: 999;
}
.bg-gradients {
    background: linear-gradient(180deg, #e6faff 0%, #fff 100%);
}
.input-hidden input[type='radio'] {
    display: none;
}
[type='radio'] {
    color: #70c0cc;
}
[type='radio']:focus {
    --tw-ring-color: none;
}
.bg-gradients {
    background: linear-gradient(180deg, #e6faff 0%, #fff 100%);
}

.bgcolorProductOverlay {
    background: linear-gradient(180deg, #e6faff 0%, #ffffff 100%), #d9d9d9;
    background-attachment: fixed;
    height: 90vh;
}

.pl-flow-background {
    background: linear-gradient(180deg, #E6FAFF 38.02%, rgba(255, 255, 255, 0.00) 100%);
  }

  .pl-flow-loading-background {
    background: linear-gradient(180deg, #E6FAFF 0%, #FFF 100%);
  }
  .radio-btn-box {
    display: none;
  }
  
  .radio-btn-box {
    background: url(./../public/assets/images/checkbox-unchecked.svg) no-repeat;
    color: white;
    display: inline-block;
  }
  
  .radio-btn-box:checked {
    background: url(./../public/assets/images/checkbox-checked.svg) no-repeat;
    border: none !important;
    color: white !important;
    padding-right: 1.4vw;
    background-size: 18px 18px;
  }
  
  .remove-scrollbar-with-scroll{
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .remove-scrollbar-with-scroll::-webkit-scrollbar {
    display: none;
  }
  .remove-scrollbar-with-scroll-y {
    overflow-x: hidden; /* Hide the horizontal scrollbar */
    overflow-y: auto; /* Always show vertical scrollbar */
  }